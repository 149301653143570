export default {
  headers: {
    myAccount: 'MY ACCOUNT',
    checkout: 'CHECKOUT',
    signIn: 'SIGN IN',
    forgottenPassword: 'FORGOTTEN PASSWORD',
    createAccount: 'CREATE AN ACCOUNT',
    checkoutSignUp: 'NEW CUSTOMER',
  },
  header: {
    exit: 'Exit',
    back: 'Back',
  },
  email: {
    header: "What's your email?",
    header2: 'Forgot your password?',
    headerGuest: 'No account needed',
    label: 'Email address',
    cta: 'Continue',
    ctaGuest: 'Continue as a guest',
    subtext:
      "If you don't have an account with us yet, you will be asked to create one",
    subtextGuest:
      'We will only use your email address to help you confirm and manage your order',
    secure: 'SECURE CHECKOUT',
  },
  password: {
    label: 'Password',
    cta: 'Sign in',
    continue: 'Continue',
    anchor: 'Forgotten Password',
    errorMessage:
      'Unable to find a match for your email or password. Please check your details and try again.',
    forgotPassword: 'Forgot password?',
  },
  reset: {
    header: "What's your email?",
    header2: 'Check your email',
    label: 'Password',
    cta: 'Continue',
    anchor: 'Back to login',
    confirmation:
      "We've sent an email with a link to create a new password. If you cannot find it in your inbox, try checking your junk folder as well.",
  },
  account: {
    titleLabel: 'Title',
    firstNameLabel: 'First name',
    lastNameLabel: 'Last name',
    passwordLabel: 'Password',
    dateOfBirthLabel: 'Date of birth',
    cta: 'Create an account',
    age: 'You need to be 16 or over to shop with River Island',
    ctaV2: 'Sign up',
    tcPartOne: 'By signing up you agree to our ',
    tcPartTwo: 'terms and conditions.',
  },
  infos: {
    checkboxCopyV2:
      'Keep me in the loop about the latest drops from River Island and exclusive discounts',
    checkboxCopy:
      'Tick here to get the latest fashion news, exclusive discounts and updates from River Island direct to your inbox.',
    legal:
      'You must be 16 or over to shop with River Island. Your personal details are safe with us. For more info, read our ',
    checkoutLegal:
      'Your personal details are safe with us. For more info, read our ',
    privacy: 'Privacy Notice.',
    redirectAnchor: 'Choose another email',
  },
  formErrors: {
    email: 'Enter your email address',
    firstName: 'Enter your first name',
    lastName: 'Enter your last name',
    enterPassword: 'Enter your password',
    retypeEmail: 'Re-type your email',
    retypePassword: 'Re-type your password',
  },
  formValidations: {
    email: 'Enter a valid email address, e.g. in the format user@domain.com',
    retypeEmail: `Your email address doesn't match - please try again`,
    retypePassword: 'Your password doesn’t match - please try again',
    passwordWrongFormat: 'For extra security your password must fit criteria',
    dateOfBirth: 'A valid date of birth is required',
    charsNotAllowed:
      'We are currently unable to process inputs with special characters. Please check your details and try again',
    duplicatePassword:
      'Your new password cannot be the same as your current password.',
    captchaError: 'Please complete the challenge below',
  },
  newsletter: {
    copy: 'Tick here to get the latest fashion news, exclusive discounts and updates from River Island direct to your inbox.',
  },
  apiError: 'Something went wrong. Please try again later.',
  tabs: {
    account: 'With an account',
    guest: 'As a guest',
  },
  benefits: {
    title: 'Benefits of having an account with us',
    checkout: 'Faster Checkout',
    orders: 'All Orders in one place',
    wishlist: 'Your wishlist everywhere',
    sale: 'Sales updates',
  },
  underConstruction: {
    title: 'Under Construction',
    headline: `THIS FEATURE IS STILL IN THE WORKS`,
    para: 'To thank you for your interest, we are offering a ',
    paraBold: '10% discount',
    promoCode: 'Guest10',
    cta: 'Copy promo code & continue',
    subtext:
      'Use the promo code above on the payment page and get 10% off your basket',
  },
  loginOverlay: {
    noAccountText:
      "If you don't have an account with us yet, you will be asked to create one",
    continueWithEmail: 'Continue with email',
    continueWith: {
      apple: 'Continue with Apple',
      google: 'Continue with Google',
    },
    or: 'Or',
  },
  errorPage: {
    title: 'Something went wrong',
    content:
      'We’re sorry, we seem to have encountered an error when trying to log you in.',
    backToHome: 'Back to home',
    goToHomePage: 'Go back to the homepage',
    notFoundContent: 'The page you were looking for couldn’t be found',
  },
};
